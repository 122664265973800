// Screen sizes
$breakpoint-large-desktop: 1806px;
$breakpoint-desktop: 1440px;
$breakpoint-laptop: 1280px;
$breakpoint-ipad: 1024px;
$breakpoint-tablet: 768px;
$breakpoint-phone: 576px;

$color-bg: #EAEFFB;

$color-primary: #2F303E;
$color-light-gray: #E0E6F3;
$color-purple-gray: #6B6F8B;
$color-blue: #2730F9;
$color-white: #FFFFFF;
$color-black: #0C0B0D;
$color-purple: #DDE0FB;
$color-dark-purple: #D3D5FF;
$color-grey: #D3DAE7;

$color-hover: #1E26CB;

$color-overlay: rgba(0, 0, 0, 0.6);

// From large desktop
@mixin large-desktop {
  @media (max-width: $breakpoint-large-desktop - 0.2px) {
    @content;
  }
}

// From desktop
@mixin laptop {
  @media (max-width: $breakpoint-desktop - 0.2px) {
    @content;
  }
}

// From laptop
@mixin ipad {
  @media (max-width: $breakpoint-laptop - 0.2px) {
    @content;
  }
}

// From ipad
@mixin tablet {
  @media (max-width: $breakpoint-ipad - 0.2px) {
    @content;
  }
}

// From ipad
@mixin landscape {
  @media (max-width: $breakpoint-tablet - 0.2px) {
    @content;
  }
}

// From phone
@mixin phone {
  @media (max-width: $breakpoint-phone - 0.2px) {
    @content;
  }
}



