@import '../../../../scss/variables';

.partners {
  &__wrapper {
    max-width: 1511px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto 256px;

    @include tablet {
      margin-bottom: 120px;
    }

    @include phone {
      margin-bottom: 116px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    color: $color-purple-gray;
    margin: 0 auto 30px;

    @include phone {
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 20px;
    }
  }

  &__list {
    position: absolute;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 22px;
    margin: auto;
    width: calc(130px * 10);

    @include tablet {
      max-width: 1168px;
    }

    @include landscape {
      gap: 16px;
      width: 884px;
    }

    @include phone {
      gap: 13px;
    }

    &-container {
      overflow: hidden;
      position: relative;
      height: 130px;
      margin-bottom: 22px;

      @include tablet {
        height: 95px;
      }

      @include landscape {
        height: 70px;
      }
    }
  }
  
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px;
    width: 130px;
    background-color: $color-light-gray;
    box-shadow: 9px 9px 19px rgba(246, 28, 13, 0.02);
    border-radius: 36px;

    @include tablet {
      height: 94px;
      width: 94px;
      border-radius: 26px;
    }

    @include landscape {
      height: 70px;
      width: 70px;
      border-radius: 16px;
    }
  }
}

.item__img {
  @include tablet {
    max-height: 44px;
    max-width: 50px;
  }
}

.list-margin {
  margin-bottom: 22px;
}

.lopper-item {
  margin-right: 22px;
}