@import '../../scss/variables';

.container {
  padding-top: 262px;
  overflow-x: hidden;

  @include ipad {
    padding-top: 190px;
  }

  @include tablet {
    padding-top: 161px;
  }

  @include landscape {
    padding-top: 161px;
  }

  @include phone {
    padding-top: 78px;
  }
}