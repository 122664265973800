@import '../../scss/variables';

.main {
  max-width: 1440px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: auto;
}

.container {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 100px;
  padding-right: 200px;
  white-space: pre-wrap;
  margin: 0 auto;
  gap: 162px;

  @include ipad {
    gap: 100px;
    padding-left: 100px;
    padding-right: 100px;
  }

  @include tablet {
    gap: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @include landscape {
    gap: 0;
    flex-direction: column-reverse;
    padding-left: 0;
    padding-right: 0;
  }
}

.error-code {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #2730F9;
  margin: 0;
}

.error-title {
  font-weight: 700;
  font-size: 52px;
  line-height: 52px;
  letter-spacing: -0.02em;
  color: $color-primary;
  margin: 8px 0 20px;

  @include landscape {
    font-size: 30px;
    line-height: 30px;
    margin: 0 0 12px;
  }
}

.error-description {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: $color-purple-gray;
  margin-bottom: 24px;
  margin-top: 0;

  @include landscape {
    font-size: 14px;
    line-height: 21px;
  }
}

.error-img-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > img {
    max-width: 400px;
    height: auto;

    @include landscape {
      width: 251px;
      height: 256px;
      padding-left: 34px;
      padding-right: 34px;
    }
  }
}

.info {
  margin-top: 28px;
  padding-left: 12px;
  padding-right: 12px;
}