@import '../../scss/variables';

.header {
  display: none;

  @include tablet {
    display: block;
    padding: 24px 20px;
    height: auto;
  }
}

.policy-container {
  display: flex;
  align-items: flex-start;
  max-width: 1160px;
  margin: auto;
  padding-top: 60px;

  @include ipad {
    padding-right: 40px;
    padding-left: 40px;
  }

  @include tablet {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
}

.logo {
  height: 32px;
  width: 164px;
}

.aside {
  position: sticky;
  top: 60px;
  left: 0;
  margin-right: 132px;

  @include ipad {
    top: 0;
  }

  @include tablet {
    top: 60px;
  }

  @include tablet {
    margin-right: 0;
    width: 100%;
  }

  &-logo-link {
    @include tablet {
      display: none;
    }
  }

  &-nav {
    margin-top: 73px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    @include tablet {
      margin: 10px 0;
      display: flex;
      overflow: auto;
      padding: 0 16px;
      max-width: 100%;
    }
  }
}

.nav-link {
  display: flex;
  width: max-content;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: $color-purple-gray;
  text-decoration: none;
  white-space: nowrap;

  &--active, &:hover {
    color: $color-primary;
  }

  @include tablet {
    margin-right: 8px;
    background: $color-light-gray;
    border-radius: 55px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 14px 24px;
  }
}

.policy-content {
  @include tablet {
    padding-left: 16px;
    padding-right: 16px;

  }

  section:not(:last-of-type) {
    border-bottom: 1px solid $color-dark-purple;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }

  section:last-of-type {
    margin-bottom: 244px;
  }

  h1, h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.02em;
    margin-top: 0;
  }

  h1 {
    margin-bottom: 14px;
  }

  h2 {
    margin-bottom: 22px;
  }

  span {
    display: block;
    margin-bottom: 22px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: rgba(107, 111, 139, 0.6);
  }

  p {
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: $color-purple-gray;
    margin: 0 0 24px;
  }
}

.actions {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  margin-bottom: 22px;
  padding: 0;

  > a, > li {
    color: $color-primary;
    background: $color-light-gray;
    border-radius: 55px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 14px 24px;
    justify-content: center;
    width: max-content;
    text-decoration: none;

    @include landscape {
      width: unset;
    }
  }
}