@import '../../scss/variables';

.Button {
  display: flex;
  align-items: center;
  border-radius: 66px;
  border: none;
  padding: 18px 26px;
  font-family: 'Inter Tight', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;

  &:hover {
    background: $color-hover;
    color: $color-white;
  }

  &--color-primary {
    background: $color-blue;
    color: $color-white;
  }

  &--color-white {
    background: $color-white;
    color: $color-primary;
  }

  &--size-big {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &--size-small {
    padding: 16px 26px;
  }

  &--shadow {
    box-shadow: 6px 6px 12px rgba(39, 48, 249, 0.22);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 13px;
  }
}

.link {
  display: flex;
  width: max-content;
  border: 1px solid $color-blue;
  border-radius: 45px;
  text-decoration: none;
  padding: 16px 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: $color-blue;

  &:hover {
    color: $color-hover;
    border-color: $color-hover;
  }
}