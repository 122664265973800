@font-face {
  font-family: 'Inter Tight';
  src: url('InterTight-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Tight';
  src: url('InterTight-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Tight';
  src: url('InterTight-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

