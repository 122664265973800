@import 'variables';
@import 'font/stylesheet';

html {
  font-size: 12px;
}

html,
body,
#root,
.App {
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }

  * {
    outline: none;
  }
}

body {
  font-size: 16px;
  transition: filter 0.2s;
  font-family: 'Inter Tight', sans-serif !important;
  margin: 0;
  background: $color-bg;
  min-height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  max-width: $breakpoint-large-desktop;
  margin: auto;
}

.overlay {
  display: none;

  @include tablet {
    position: fixed;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $color-overlay;
    z-index: 1;
  }
}
