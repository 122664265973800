@import '../../scss/variables';

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1234px;
  width: 100%;
  margin: auto;
  padding-bottom: 27px;

  @include laptop {
    padding: 0 0 27px;
  }

  @include ipad {
    width: unset;
    margin: unset;
    padding: 0 27px 27px;
  }

  @include phone {
    padding: 0 12px 12px;
    flex-direction: column;
    align-items: flex-start;
  }

  &__logo {
    height: 34px;
  }

  &__link {
    display: block;
    text-decoration: none;
    color: $color-blue;

    &--margin {
      margin-top: 25px;
    }

    &:hover {
      color: $color-hover;
    }
  }

  &__email {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: -0.04em;
    color: $color-purple-gray;
    max-width: 343px;

    @include phone {
      margin-top: 16px;
      text-align: left;
      justify-content: unset;
      margin-bottom: 34px;
    }
  }

  &__application-btn {
    display: none !important;

    @include phone {
      display: flex !important;
    }
  }
}