@import '../../../../scss/variables';

.service {
  &-container {
    max-width: 1282px;
    margin: 0 auto 216px;

    @include laptop {
      padding: 0 79px;
    }

    @include ipad {
      padding-left: 24px;
      padding-right: 24px;
      margin-bottom: 194px;
    }

    @include tablet {
      padding-top: 0;
      max-width: 976px;
      margin-bottom: 206px;
    }

    @include landscape {
      margin-bottom: 166px;
    }

    @include phone {
      margin-bottom: 125px;
    }
  }

  &-list--desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;

    @include ipad {
      grid-gap: 14px;
    }

    @include tablet {
      display: none;
    }
  }

  &-list--mobile {
    display: none;

    @include tablet {
      display: block;
    }
  }

  &-list__right {
    margin-top: 140px;
  }

  &-title {
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 70px;
    text-align: center;
    letter-spacing: -0.02em;
    color: $color-primary;
    margin-top: 0;
    margin-bottom: 78px;

    @include tablet {
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 24px;
    }
  }
}

.card__img {
  display: block;
  margin: auto;
  right: 0;
  top: 0;

  @include tablet {
    position: absolute;
    height: 158px;
  }

  @include landscape {
    max-height: 162px;
  }

  @include phone {
    height: unset;
    position: unset;
  }
}
