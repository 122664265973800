@import '../../../../scss/variables';

.application {
  background: $color-light-gray;
  border-radius: 22px;
  padding: 89px 0 34px 0;
  position: relative;
  margin-bottom: 20px;

  @include landscape {
    padding: 89px 122px 34px;
  }

  @include phone {
    padding: 58px 12px 20px;
    margin-bottom: 12px;
  }

  &-wrapper {
    margin: 0 auto 130px;
    max-width: 1268px;

    @include laptop {
      margin: 0 86px 269px;
    }

    @include tablet {
      margin: 0 24px 269px;
      max-width: 976px;
    }

    @include ipad {
      margin-bottom: 80px;
    }

    @include phone {
      margin-bottom: 60px;
    }
  }

  &__icon {
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 126px;
    width: 126px;

    @include phone {
      height: 85px;
      width: 85px;
      top: -40px;

      > svg {
        height: 85px;
        width: 85px;
      }
    }
  }

  &__description {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.02em;
    color: $color-primary;
    margin: 0 auto 20px;

    @include phone {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__button {
    margin: auto;
  }
}

.note {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.02em;
  color: $color-purple-gray;
  white-space: pre-wrap;

  @include phone {
    font-size: 11px;
    line-height: 15px;
  }
}