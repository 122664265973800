@import '../../../../scss/variables';

.main-section {
  max-width: 1483px;
  padding-top: 0;
  padding-bottom: 215px;
  margin: 0 auto;
  position: relative;

  @include ipad {
    max-width: 976px;
    padding-bottom: 182px;
    margin-top: 0;
    margin-bottom: 0;
  }

  @include tablet {
    max-width: 724px;
    padding-bottom: 154px;
    overflow: hidden;
  }

  @include landscape {
    max-width: 665px;
  }

  @include phone {
    padding-top: 60px;
    max-width: 422px;
    overflow: hidden;
  }

  @media (min-width: 440px) {
    overflow: unset;
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 70px;
    text-align: center;
    letter-spacing: -0.02em;
    color: $color-primary;
    white-space: pre-wrap;
    margin: 0 auto 26px;


    @include tablet {
      font-size: 60px;
      line-height: 60px;
    }

    @include landscape {
      white-space: pre-wrap;
      font-size: 28px;
      line-height: 28px;
    }
  }

  &__description {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    color: $color-purple-gray;
    margin: 0 auto 24px;
    max-width: 470px;

    //@include tablet {
    //  margin-bottom: 86px;
    //}

    @include phone {
      font-size: 14px;
      line-height: 21px;
      //margin-bottom: 88px;
    }
  }

  &__offer {
    position: absolute;
    background: $color-purple;
    box-shadow: 6px 6px 12px rgba(185, 188, 255, 0.04);
    border-radius: 66px;
    transform: rotate(-1.5deg);
    padding: 10px 24px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    color: $color-primary;
    left: 0;
    right: 0;
    bottom: 300px;
    margin-left: auto;
    margin-right: auto;
    width: max-content;

    @include ipad {
      bottom: 269px;
    }

    @include tablet {
      bottom: 202px;
      font-size: 16px;
      line-height: 24px;
    }

    @include phone {
      bottom: 198px;
      white-space: pre-wrap;
      font-size: 12px;
      line-height: 18px;
      max-width: 224px;
      padding: 10px 12px;
    }
  }

  &__button {
    margin: auto;
  }
}

.icon {
  position: absolute;

  &-binance {
    left: 67px;
    top: -100px;

    > svg {
      width: 102px;
      height: 120px;
    }

    @include ipad {
      left: -31px;
      top: -13px;
    }

    @include tablet {
      width: 113px;
      left: -31px;
      top: -56px;
      transform: rotate(22.95deg);
    }

    @include phone {
      left: -31px;
      top: -13px;
    }
  }

  &-netflix {
    left: 200px;
    top: 93px;

    > svg {
      width: 116px;
      height: 101px;
    }

    @include ipad {
      left: 0;
      top: 188px;
    }

    @include tablet {
      width: 123px;
      left: 0;
      top: 188px;
      transform: rotate(-5.34deg);
    }

    @include phone {
      left: -47px;
      top: 188px;
    }
  }

  &-amazon {
    left: 42px;
    bottom: 171px;

    > svg {
      width: 129px;
      height: 91px;
    }

    @include ipad {
      left: -49px;
      top: 392px;
    }

    @include tablet {
      width: 123px;
      left: -49px;
      top: 359px;
      transform: rotate(10.38deg);
    }

    @include phone {
      left: -6px;
      top: 350px;
    }
  }

  &-youtube {
    right: 74px;
    top: -70px;

    > svg {
      width: 120px;
      height: 79px;
    }

    @include ipad {
      right: -62px;
      top: 4px;
    }

    @include tablet {
      width: 145px;
      right: -55px;
      top: -34px;
    }

    @include phone {
      right: -49px;
      top: -3px;
    }
  }

  &-discord {
    right: 174px;
    top: 76px;

    > svg {
      width: 129px;
      height: 91px;
    }

    @include ipad {
      right: -26px;
      top: 151px;
    }

    @include tablet {
      width: 123px;
      right: -26px;
      top: 151px;
    }

    @include phone {
      right: -59px;
      top: 190px;
    }
  }

  &-spotify {
    right: 11px;
    bottom: 118px;

    > svg {
      width: 103px;
      height: 103px;
    }

    @include ipad {
      right: -61px;
      top: 337px;
    }

    @include tablet {
      width: 124px;
      right: -61px;
      top: 337px;
      transform: rotate(-65.39deg)
    }

    @include phone {
      width: 132px;
      right: -48px;
      top: 336px;
    }
  }
}
