@import '../../scss/variables';

.container {
  margin: auto;
  position: fixed;
  z-index: 1094;
  width: -webkit-fill-available;
  top: 41px;
  left: 0;
  right: 0;
  max-width: 991px;

  @include landscape {
    top: 0;
    margin: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-white;
  padding: 12px 12px 12px 34px;
  border-radius: 77px;
  margin: auto;
  position: relative;

  &__buttons-wrapper {
    display: flex;
  }

  &__logo-area {
    display: flex;
    gap: 27px;

    @include landscape {
      gap: 23px;
    }
  }

  &--mobile {
    @include tablet {
      border-radius: 44px 44px 0 0;
    }
  }

  @include ipad {
    margin: 0 24px;
  }

  @include landscape {
    margin: 0;
    border-radius: 0;
    padding: 26px 26px 26px 19px;
  }

  &__application-btn {
    @include landscape {
      display: none !important;
    }
  }

  &__logo-link {
    display: flex;
  }
}

.nav {
  display: flex;
  list-style: none;

  @include tablet {
    display: none;
  }

  &__link {
    display: block;
    text-decoration: none;
    color: $color-primary;

    &:not(:last-of-type) {
      margin-right: 26px;
    }

    &:hover, &--active {
      color: $color-hover;
    }
  }
}

.language-btn {
  border: none;
  background: none;
  color: $color-purple-gray;
  margin-right: 35px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;

  &:hover {
    color: $color-hover;

    @include landscape {
      margin-right: 0;
    }
  }

  @include landscape {
    margin: 0;
  }
}

.logo {
  width: 173px;
  height: 34px;

  @include landscape {
    width: auto;
    height: 25px;
  }
}

.mobile-menu__control {
  display: none;
}

.mobile-menu__control {
  background: none;
  border: none;

  @include tablet {
    display: block;
  }
}

.mobile-menu {
  display: none;

  @include tablet {
    display: flex;
    flex-direction: column;
    height: 0;
    position: absolute;
    justify-content: center;
    top: 75px;
    left: 0;
    background: $color-white;
    border-radius: 0 0 44px 44px;
    transition: .2s;
    width: -webkit-fill-available;

    .mobile-menu__button {
      display: none;
    }
  }

  @include landscape {
    border-radius: 0 0 20px 20px;
  }
}

.mobile-menu__nav {
  display: none;
}

.mobile-menu--open {
  @include tablet {
    border-top: 1px solid $color-light-gray;
    height: 100%;
    top: 79px;
    padding: 30px 0;
    z-index: 1;
  }

  @include landscape {
    top: 78px;
    padding: 55px 12px 75px;
  }

  .mobile-menu__nav {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .nav__link {
    margin: 0;
  }

  .mobile-menu__button {
    @include landscape {
      padding: 14px;
      margin: 26px auto;
      display: flex;
    }
  }
}

.control__icon {
  position: relative;
  cursor: pointer;

  &:before, &:after {
    content: "";
    position: absolute;
    background: $color-primary;
    border-radius: 7px;
    width: 18px;
    height: 2px;
    top: 50%;
    left: 50%;
    transition: .2s;
  }

  &:before {
    transform: translateX(-50%) translateY(-50%) translateY(3px);
  }

  &:after {
    transform: translateX(-50%) translateY(-50%) translateY(-3px);
  }

  &--open:before {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }

  &--open:after {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
}

.mobile-menu__overlay {
  display: none;
}

