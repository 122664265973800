@import '../../scss/variables';

.card {
  background-color: $color-light-gray;
  border-radius: 32px;
  margin-bottom: 32px;
  transition: opacity 2s;
  opacity: 0;

  @include tablet {
    display: flex;
    overflow: hidden;
    position: relative;
    min-height: 158px;
    padding: 0 30px;
    cursor: pointer;
  }

  @include phone {
    display: block;
    position: unset;
    border-right: 22px;
    margin-bottom: 10px;
    padding: 0;
  }

  &__content {
    transition: .2s;
    padding: 0 40px 40px;
    z-index: 1;

    @include tablet {
      padding: 0;
      display: unset;
      margin-top: 56px;
    }

    @include phone {
      display: block;
      padding: 0 14px 20px;
      margin-top: 10px;
    }
  }

  &__title {
    color: $color-primary;
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 53px;
    letter-spacing: -0.02em;
    margin: 10px auto 20px;
    transition: .2s;
    white-space: pre-wrap;

    @include ipad {
      font-size: 30px;
      line-height: 36px;
    }

    @include tablet {
      margin: 0;
      white-space: pre-wrap;
    }

    @include phone {
      font-size: 22px;
      line-height: 26px;
      white-space: unset;
    }
  }

  &__description {
    color: $color-purple-gray;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    white-space: pre-wrap;
    margin: 0 auto 24px;
    transition: .2s;
    opacity: 1;

    @include tablet {
      margin: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
    }
  }

  &__button {
    @include tablet {
      visibility: hidden;
      height: 0;
      padding: 0;
      opacity: 0;
    }

    &--selected {
      @include tablet {
        opacity: 1;
        visibility: visible;
        height: auto;
        padding: 18px 26px;
        margin-bottom: 30px;
      }
    }

    &--disabled {
      background-color: $color-grey;
      color: $color-purple-gray;
      cursor: unset;

      &:hover {
        background-color: $color-grey;
        color: $color-purple-gray;
      }
    }
  }

  &--selected {
    @include tablet {
      p {
        opacity: 1;
        margin: 20px auto 24px;
        height: auto;
        visibility: visible;
      }
    }

    @include phone {
      button {
        margin-bottom: 0;
      }

      h3 {
        margin-top: 0;
      }
    }
  }
}