/* Looper styles */
@keyframes slideAnim {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.looper {
  width: 100%;
  overflow: hidden;
}

.looper__innerList {
  display: flex;
  justify-content: center;
  width: fit-content;
}

.looper__innerList[data-animate="true"] .looper__listInstance {
  animation: slideAnim linear infinite;
}

.looper__listInstance {
  display: flex;
  width: max-content;
  animation: none;
}

.contentBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  margin: 10px;
  padding: 16px;
  font-weight: 600;
  text-align: center;
  border-radius: 16px;
}

.contentBlock--one {
  width: 120px;
  background: rgba(255, 255, 255, 0.05);
  font-weight: 600;
  font-size: 18px;
}

.contentBlock--one:last-of-type {
  margin-right: 100px;
}

.contentBlock--two {
  font-size: 48px;
}